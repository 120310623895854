// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'
import type { RouteRecordRaw } from 'vue-router'
import warehouseSettings from './_settings'
import warehouseStats from './_stats'

const basePath = '/wh'

let routes: Array<RouteRecordRaw> = [
    {
        path: basePath,
        name: 'Magazzino',
        redirect: `${basePath}/product-sheets`,
        component: AppLayout,
        children: [
            {
                path: `${basePath}/product-sheets`,
                name: 'Elenco schede prodotto',
                meta: {
                    title: 'Schede prodotto',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/2dce0c4c-1c95-47cc-b4d2-8881821568ae',
                            label: '2.1 - Ricercare una scheda prodotto',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/0201865b-f6b3-47eb-b69d-7d84bb52206e',
                            label: '2.3 - Aggiungere un prodotto ordinario specifico',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/95565e37-b992-41ea-aed6-964d032c4895',
                            label: '2.4 - Dividere la quantità di una riga di prodotti ordinari specifici',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/eb164b19-3c89-4d9a-9c3d-6c599b020347',
                            label: '2.5 - Raggruppare i prodotti ordinari specifici di una scheda prodotto ordinario',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/7430bf5a-a5be-475c-9e46-249e85638dcb',
                            label: '2.6 - Aggiungere un contenitore di prodotti in una scheda prodotto composto',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/a73d3f1e-061e-4c09-921b-4b484d7a9c4b',
                            label: "2.7 - Inserire un prodotto ordinario specifico all'interno di un contenitore di una scheda prodotto composto",
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/d3334515-689c-478b-b2f5-2cabf90c592e',
                            label: "2.8 - Eliminare prodotto ordinario specifico all'interno di un contenitore di una scheda prodotto composto",
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/11c4b2a3-29bb-4d3e-b0cb-88a6d4b39e62',
                            label: '2.9 - Inserire una scheda prodotto nella lista di composizione di una scheda prodotto virtuale',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/warehouse/components/ProductSheetsIndex.vue'
                    ),
            },
            {
                path: `${basePath}/product-sheets-planner`,
                name: 'Planner schede prodotto',
                meta: {
                    title: 'Planner schede prodotto',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/cd2ae234-8639-4abf-be85-5f89002e908e',
                            label: '2.15 - Planner materiale del magazzino',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/warehouse/components/ProductsSheetPlanner.vue'
                    ),
            },
            {
                path: `${basePath}/product-sheets-planner-range`,
                name: 'Planner schede prodotto in periodo',
                meta: {
                    title: 'Planner scheda prodotto in periodo',
                    guides: [
                        // Nessuna per scelta
                    ],
                },
                component: () =>
                    import(
                        '@/modules/warehouse/components/ProductsSheetPlanner.vue'
                    ),
            },
            {
                path: `${basePath}/product-sheet/add`,
                name: 'Aggiungi schede prodotto',
                meta: {
                    title: 'Aggiungi scheda prodotto',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/02c2faab-1c1e-4a17-819e-5a8027a77500',
                            label: '2.2 - Aggiungere una scheda prodotto',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/warehouse/components/ProductSheetAdd.vue'
                    ),
            },

            // Statistiche
            warehouseStats,

            // Impostazioni
            warehouseSettings,

            {
                path: `${basePath}/product-sheet/:id`,
                name: 'Scheda prodotto',
                meta: {
                    // title: '◌',
                },
                redirect: (to) => removeTrailingSlash(to.fullPath) + '/general',
                component: () =>
                    import('@/modules/warehouse/components/ProductSheet.vue'),
                children: [
                    {
                        path: 'general',
                        name: 'Scheda prodotto generale',
                        component: () =>
                            import(
                                '@/modules/warehouse/components/ProductSheetGeneral.vue'
                            ),
                    },
                    {
                        path: 'economic-data',
                        name: 'Scheda prodotto dati economici',
                        component: () =>
                            import(
                                '@/modules/warehouse/components/ProductSheetEconomicData.vue'
                            ),
                    },
                    {
                        path: 'details-ordinary',
                        name: 'Scheda prodotto dettagli p. ordinario',
                        meta: {
                            title: 'Scheda prodotto dettagli p. ordinario',
                            guides: [
                                {
                                    url: 'https://howto.madeinshow.app/guides/0201865b-f6b3-47eb-b69d-7d84bb52206e',
                                    label: '2.3 - Aggiungere un prodotto ordinario specifico',
                                },
                                {
                                    url: 'https://howto.madeinshow.app/guides/95565e37-b992-41ea-aed6-964d032c4895',
                                    label: '2.4 - Dividere la quantità di una riga di prodotti ordinari specifici',
                                },
                                {
                                    url: 'https://howto.madeinshow.app/guides/eb164b19-3c89-4d9a-9c3d-6c599b020347',
                                    label: '2.5 - Raggruppare i prodotti ordinari specifici di una scheda prodotto ordinario',
                                },
                            ],
                        },
                        component: () =>
                            import(
                                '@/modules/warehouse/components/ProductSheetDetailOrdinary.vue'
                            ),
                    },
                    {
                        path: 'details-composite',
                        name: 'Scheda prodotto dettagli p. composto',
                        meta: {
                            title: 'Scheda prodotto dettagli p. composto',
                            guides: [
                                {
                                    url: 'https://howto.madeinshow.app/guides/7430bf5a-a5be-475c-9e46-249e85638dcb',
                                    label: '2.6 - Aggiungere un contenitore di prodotti in una scheda prodotto composto',
                                },
                                {
                                    url: 'https://howto.madeinshow.app/guides/a73d3f1e-061e-4c09-921b-4b484d7a9c4b',
                                    label: "2.7 - Inserire un prodotto ordinario specifico all'interno di un contenitore di una scheda prodotto composto",
                                },
                                {
                                    url: 'https://howto.madeinshow.app/guides/d3334515-689c-478b-b2f5-2cabf90c592e',
                                    label: "2.8 - Eliminare prodotto ordinario specifico all'interno di un contenitore di una scheda prodotto composto",
                                },
                            ],
                        },
                        component: () =>
                            import(
                                '@/modules/warehouse/components/ProductSheetDetailComposite.vue'
                            ),
                    },
                    {
                        path: 'details-virtual',
                        name: 'Scheda prodotto dettagli p. virtuale',
                        meta: {
                            title: 'Scheda prodotto dettagli p. virtuale',
                            guides: [
                                {
                                    url: 'https://howto.madeinshow.app/guides/11c4b2a3-29bb-4d3e-b0cb-88a6d4b39e62',
                                    label: '2.9 - Inserire una scheda prodotto nella lista di composizione di una scheda prodotto virtuale',
                                },
                            ],
                        },
                        component: () =>
                            import(
                                '@/modules/warehouse/components/ProductSheetDetailVirtual.vue'
                            ),
                    },
                    {
                        path: 'attachments',
                        name: 'Scheda prodotto allegati',
                        component: () =>
                            import(
                                '@/modules/warehouse/components/ProductSheetAttachments.vue'
                            ),
                    },
                    {
                        path: 'reservations',
                        name: 'Scheda prodotto prenotazioni',
                        component: () =>
                            import(
                                '@/modules/warehouse/components/ProductSheetReservations.vue'
                            ),
                    },
                    {
                        path: 'history',
                        name: 'Scheda prodotto storico',
                        component: () =>
                            import(
                                '@/modules/warehouse/components/ProductSheetHistory.vue'
                            ),
                    },
                ],
            },
        ],
    },
]

export const WarehouseRouter: Array<RouteRecordRaw> = routes
