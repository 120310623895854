// Declare state
import { t } from '@/locales'
import { useInvoiceStore } from '@/modules/company-management/store/invoice-store'
import { goToKeyAsRoute } from '@/modules/sls/helpers/menu-helpers'
import { isItemVisible } from '@/store/action-helpers'
import type { MenuItem } from 'primevue/menuitem'

export type MenuStoreState = {
    menu: MenuItem[]
}

export const getAllMenuItems = (): MenuItem[] => {
    const productionModule: MenuItem[] = [
        {
            label: t('PR.module_name'),
            icon: 'far fa-camera-movie',
            iconBarItems: [
                {
                    label: t('SLS.research'),
                    key: '/pr/search',
                    icon: 'far fa-search',
                    command: goToKeyAsRoute,
                    visible: () =>
                        isItemVisible([
                            'pr,search_binders',
                            'pr,search_productions',
                        ])() ||
                        isItemVisible([
                            'pr,search_ext_entries',
                            'pr,get_entry_types',
                            'pr,get_production_statuses',
                            'ab,get_suppliers_or_technicians',
                        ])(),
                },
                {
                    label: t('SLS.stats'),
                    key: '/pr/stats',
                    icon: 'far fa-chart-line',
                    command: goToKeyAsRoute,
                    visible: () =>
                        isItemVisible(
                            'stats,get_ext_entry_suppliers_stats',
                        )() || isItemVisible('stats,get_customers_stats')(),
                },
                {
                    label: t('SLS.settings'),
                    key: '/pr/settings',
                    icon: 'far fa-cog',
                    command: goToKeyAsRoute,
                    visible: isItemVisible([
                        'pr,get_locations',
                        'pr,update_location',
                    ]),
                },
            ],
            items: [
                {
                    label: t('SLS.planner'),
                    key: '/pr/planner',
                    icon: 'far fa-calendar-alt',
                    command: goToKeyAsRoute,
                },
                {
                    label: t('PR.production_list'),
                    key: '/pr/production/list',
                    icon: 'far fa-list-alt',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('pr,search_productions'),
                },
                {
                    label: t('PR.team_compositions_planner_short'),
                    key: '/pr/team-compositions-planner',
                    icon: 'far fa-calendar-exclamation',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('pr,get_invites_for_calendar'),
                },
            ],
        },
    ]

    const warehouseModule: MenuItem[] = [
        {
            label: t('WH.module_name'),
            icon: 'far fa-warehouse',
            iconBarItems: [
                {
                    label: t('SLS.research'),
                    key: '/wh/search',
                    visible: false,
                },
                {
                    label: t('SLS.stats'),
                    key: '/wh/stats',
                    icon: 'far fa-chart-line',
                    command: goToKeyAsRoute,
                    visible: () =>
                        isItemVisible('stats,get_warehouse_products_stats')() ||
                        isItemVisible('stats,get_prod_reservations_stats')() ||
                        isItemVisible('stats,inventory_list_pdf')(),
                },
                {
                    label: t('SLS.settings'),
                    key: '/wh/settings',
                    icon: 'far fa-cog',
                    command: goToKeyAsRoute,
                    visible: () =>
                        isItemVisible('wh,get_brands')() ||
                        isItemVisible('wh,get_cats_tree')(),
                },
            ],
            items: [
                {
                    label: t('SLS.product_sheets'),
                    key: '/wh/product-sheets',
                    icon: 'far fa-boxes',
                    command: goToKeyAsRoute,
                    visible: isItemVisible([
                        'wh,get_cats_tree',
                        'wh,get_cat_products',
                    ]),
                },
                {
                    label: t('SLS.planner'),
                    key: '/wh/product-sheets-planner',
                    icon: 'far fa-calendar-alt',
                    command: goToKeyAsRoute,
                    visible: isItemVisible([
                        'pr,get_prod_sheet_reservations_planner',
                    ]),
                },
            ],
        },
    ]

    const technicianModule: MenuItem[] = [
        {
            label: t('TC.module_name'),
            icon: 'far fa-user-hard-hat',
            iconBarItems: [
                {
                    label: t('SLS.research'),
                    key: '/tc/search',
                    visible: false,
                },
                {
                    label: t('SLS.stats'),
                    key: '/tc/stats',
                    icon: 'far fa-chart-line',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('stats,get_technicians_stats'),
                },
                {
                    label: t('SLS.settings'),
                    key: '/tc/settings',
                    icon: 'far fa-cog',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('tc,get_areas_tree'),
                },
            ],
            items: [
                {
                    label: t('TC.technical_list'),
                    key: '/tc/index',
                    icon: 'far fa-user-hard-hat',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('tc,filter_tc_by_role'),
                },
                {
                    label: t('SLS.planner'),
                    key: '/tc/planner',
                    icon: 'far fa-calendar-alt',
                    command: goToKeyAsRoute,
                    visible: isItemVisible(
                        'pr,get_all_tech_reservations_for_calendar',
                    ),
                },
                {
                    label: t('TC.planner_by_role'),
                    key: '/tc/roles-planner',
                    icon: 'far fa-calendar-star',
                    command: goToKeyAsRoute,
                    visible: isItemVisible(
                        'pr,get_role_reservations_for_calendar',
                    ),
                },
            ],
        },
    ]

    const companyVehicleModule: MenuItem[] = [
        {
            label: t('CV.module_name'),
            icon: 'far fa-truck-moving',
            iconBarItems: [
                {
                    label: t('SLS.research'),
                    key: '/cv/search',
                    visible: false,
                },
                {
                    label: t('SLS.stats'),
                    key: '/cv/stats',
                    visible: false,
                },
                {
                    label: t('SLS.settings'),
                    key: '/cv/settings',
                    visible: false,
                },
            ],
            items: [
                {
                    label: t('CV.list_of_vehicles'),
                    key: '/cv/index',
                    icon: 'far fa-truck-moving',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('cv,get_vehicles'),
                },
                {
                    label: t('SLS.planner'),
                    key: '/cv/planner',
                    icon: 'far fa-calendar-alt',
                    command: goToKeyAsRoute,
                    visible: isItemVisible(
                        'pr,get_all_company_vehicle_reservations_for_calendar',
                    ),
                },
            ],
        },
    ]

    const companyManagementModule: MenuItem[] = [
        {
            label: t('CM.module_name'),
            icon: 'far fa-receipt',
            iconBarItems: [
                {
                    label: t('SLS.research'),
                    key: '/cm/search',
                    visible: false,
                },
                {
                    label: t('SLS.stats'),
                    key: '/cm/stats',
                    visible: false,
                },
                {
                    label: t('SLS.settings'),
                    key: '/cm/settings',
                    icon: 'far fa-cog',
                    command: goToKeyAsRoute,
                    visible: () =>
                        isItemVisible('cm,get_invoice_categories')() ||
                        isItemVisible('cm,get_payment_accounts')(),
                },
            ],
            items: [
                {
                    label: 'Riassunto',
                    key: '/cm/summary',
                    icon: 'far fa-chart-line',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('cm,get_summary'),
                },
                {
                    label: 'Importa fatture',
                    icon: 'far fa-file-import',
                    items: [
                        {
                            label: 'Upload fatture passive',
                            key: '/cm/passive-invoices-upload',
                            icon: 'far fa-file-upload',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,add_invoices'),
                        },
                        {
                            label: 'Aggiungi spesa',
                            key: '/cm/cost-add',
                            icon: 'far fa-file-plus',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,add_schedule'),
                        },
                        {
                            label: 'Aggiungi entrata',
                            key: '/cm/taking-add',
                            icon: 'far fa-file-plus',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,add_schedule'),
                        },
                        {
                            label: 'Aggiungi fattura extra SDI',
                            key: '/cm/invoice-add',
                            icon: 'far fa-file-plus',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,add_invoice_manual'),
                        },
                        {
                            label: 'Aggiungi fattura estera',
                            key: '/cm/foreign-invoice-add',
                            icon: 'far fa-file-plus',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,add_invoice_manual'),
                        },
                        {
                            label: 'Upload fatture attive',
                            key: '/cm/active-invoices-upload',
                            icon: 'far fa-file-upload',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,add_invoices'),
                        },
                    ],
                },
                {
                    label: 'Gestione aziendale',
                    icon: 'far fa-analytics',
                    items: [
                        {
                            label: 'Scadenziario',
                            key: '/cm/payments-schedule',
                            icon: 'far fa-alarm-clock',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_schedules'),
                        },
                        {
                            label: 'Incassi',
                            key: '/cm/takings-schedule',
                            icon: 'far fa-coins',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_schedules'),
                        },
                        {
                            label: 'Stime',
                            key: '/cm/payments-predictions',
                            icon: 'far fa-abacus',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_schedules'),
                        },
                        {
                            label: 'Flusso di cassa',
                            key: '/cm/cash-flow',
                            icon: 'far fa-chart-line',
                            command: goToKeyAsRoute,
                            visible: isItemVisible(
                                'cm,get_schedule_totals_by_month',
                            ),
                        },
                        {
                            label: 'Fatture passive',
                            key: '/cm/passive-invoices',
                            icon: 'far fa-file-invoice',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_invoice'),
                        },
                        {
                            label: 'Fatture estere',
                            key: '/cm/foreign-invoices',
                            icon: 'far fa-file-invoice',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_invoice'),
                        },
                        {
                            label: 'Fatture attive',
                            key: '/cm/active-invoices',
                            icon: 'far fa-file-invoice',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_invoice'),
                        },
                    ],
                },
                {
                    label: 'Export fatture',
                    icon: 'far fa-file-export',
                    items: [
                        {
                            label: 'Spese',
                            key: '/cm/costs-search',
                            icon: 'far fa-shopping-cart',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_schedules'),
                        },
                        {
                            label: 'Incassi',
                            key: '/cm/takings-search',
                            icon: 'far fa-coins',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_schedules'),
                        },
                        {
                            label: 'Fatture SDI',
                            key: '/cm/passive-invoices-search/sdi',
                            icon: 'far fa-file-invoice',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_invoices'),
                        },
                        {
                            label: 'Fatture extra SDI',
                            key: '/cm/passive-invoices-search/ext',
                            icon: 'far fa-file-invoice',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_invoices'),
                        },
                        {
                            label: 'Fatture estere',
                            key: '/cm/passive-invoices-search/foreign',
                            icon: 'far fa-file-invoice',
                            command: goToKeyAsRoute,
                            visible: isItemVisible('cm,get_invoices'),
                        },
                    ],
                },
            ],
        },
    ]

    const addressBookModule: MenuItem[] = [
        {
            label: t('AB.module_name'),
            icon: 'far fa-address-book',
            iconBarItems: [
                {
                    label: t('SLS.settings'),
                    key: '/ab/settings',
                    icon: 'far fa-cog',
                    command: goToKeyAsRoute,
                    visible: () => isItemVisible('ab,import_sheets')(),
                },
            ],
            items: [
                {
                    label: t('SLS.address_book_sheets'),
                    key: '/ab/index',
                    icon: 'far fa-address-book',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('ab,get_letter'),
                },
                {
                    label: 'Icon bar',
                    iconBar: true,
                    items: [
                        {
                            label: t('SLS.research'),
                            key: '/ab/search',
                            visible: false,
                        },
                        {
                            label: t('SLS.stats'),
                            key: '/ab/stats',
                            visible: false,
                        },
                        {
                            label: t('SLS.settings'),
                            key: '/ab/settings',
                            visible: false,
                        },
                    ],
                },
            ],
        },
    ]

    const salesModule: MenuItem[] = [
        {
            label: t('SL.module_name'),
            icon: 'far fa-bullhorn',
            iconBarItems: [
                {
                    label: t('SLS.research'),
                    key: '/sl/search',
                    visible: false,
                },
                {
                    label: t('SLS.stats'),
                    key: '/sl/stats',
                    visible: false,
                },
                {
                    label: t('SLS.settings'),
                    key: '/sl/settings',
                    icon: 'far fa-cog',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('sl,add_cat'),
                },
            ],
            items: [
                {
                    label: t('SL.sales_descriptions'),
                    key: '/sl/descriptions',
                    icon: 'far fa-bullhorn',
                    command: goToKeyAsRoute,
                    visible: isItemVisible([
                        'sl,get_cat_tree',
                        'pr,update_estimate_data',
                    ]),
                },
            ],
        },
    ]

    const userModule: MenuItem[] = [
        {
            label: t('USR.users_and_groups'),
            icon: 'far fa-users',
            items: [
                {
                    label: t('USR.manage_users'),
                    key: '/usr/index',
                    icon: 'far fa-users',
                    command: goToKeyAsRoute,
                    visible: isItemVisible(['usr,get_users']),
                },
                {
                    label: t('USR.manage_groups_and_permissions'),
                    key: '/usr/groups',
                    icon: 'far fa-users-crown',
                    command: goToKeyAsRoute,
                    visible: isItemVisible(['usr,update_group_perms']),
                },
            ],
        },
    ]

    const devModule: MenuItem[] = [
        {
            label: 'Sviluppo',
            icon: 'far fa-fire',
            items: [
                {
                    label: 'Log applicazione',
                    key: '/dev/application-log',
                    icon: 'far fa-fire',
                    command: goToKeyAsRoute,
                    visible: isItemVisible('lg,get_log_app'),
                },
            ],
        },
    ]

    // {
    //     label: 'FTT importate',
    //     key: '/cm/passive-invoices-not-verified',
    //     icon: 'far fa-file-invoice',
    //     command: goToKeyAsRoute,
    //     visible: isItemVisible('cm,get_invoice'),
    // },
    // {
    //     label: 'FTT acquisti da verificare',
    //     key: '/cm/passive-invoices-assets-not-verified',
    //     icon: 'far fa-file-invoice',
    //     command: goToKeyAsRoute,
    //     visible: isItemVisible('cm,get_invoice'),
    // },
    // {
    //     label: 'FTT da verificare Sandro',
    //     key: '/cm/passive-invoices-assets-not-verified-sandro',
    //     icon: 'far fa-file-invoice',
    //     command: goToKeyAsRoute,
    //     visible: isItemVisible('cm,get_invoice'),
    // },
    const invoiceStore = useInvoiceStore()
    invoiceStore.invoiceVerificationStatus.forEach((item) => {
        if (item.show_in_menu) {
            companyManagementModule[0].items![2].items!.push({
                label: item.menu_label ?? 'Status FTT',
                key: `/cm/passive-invoices-by-status/passive/${item.v_status_id}`,
                icon: 'far fa-file-invoice',
                command: goToKeyAsRoute,
                visible: isItemVisible('cm,get_invoice'),
            })
        }
    })

    return [
        ...productionModule,
        ...warehouseModule,
        ...technicianModule,
        ...companyVehicleModule,
        ...companyManagementModule,
        ...addressBookModule,
        ...salesModule,
        ...userModule,
        ...devModule,
    ] as MenuItem[]
}

// Create initial state
export const state = (): MenuStoreState => ({
    menu: [],
})
