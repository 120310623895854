// Components
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/ab'

export default {
    path: `${basePath}/index`,
    name: 'Anagrafiche rubrica',
    meta: {
        title: 'Anagrafiche rubrica',
    },
    component: () =>
        import('@/modules/address-book/components/AddressBookIndex.vue'),
    children: [
        {
            path: 'all',
            name: 'Tutte le schede',
            meta: {
                title: 'Tutte le schede',
            },
            component: () =>
                import('@/modules/address-book/components/AddressBookAll.vue'),
        },
        {
            path: 'customers',
            name: 'Schede clienti',
            meta: {
                title: 'Schede clienti',
            },
            component: () =>
                import(
                    '@/modules/address-book/components/AddressBookCustomers.vue'
                ),
        },
        {
            path: 'suppliers',
            name: 'Schede fornitori',
            meta: {
                title: 'Schede fornitori',
            },
            component: () =>
                import(
                    '@/modules/address-book/components/AddressBookSuppliers.vue'
                ),
        },
    ],
} as RouteRecordRaw
